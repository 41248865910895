import React from "react";
import "./Lifestyle.css";

import Lifestyle1 from "../assets/Lifestyle/Lifestyle1.webp";
import Lifestyle2 from "../assets/Lifestyle/Lifestyle2.webp";
import Lifestyle3 from "../assets/Lifestyle/Lifestyle3.webp";
import Lifestyle4 from "../assets/Lifestyle/Lifestyle4.webp";
import Lifestyle5 from "../assets/Lifestyle/Lifestyle5.webp";
import Lifestyle8 from "../assets/Lifestyle/Lifestyle8.webp";
import Lifestyle9 from "../assets/Lifestyle/Lifestyle9.webp";
import Lifestyle10 from "../assets/Lifestyle/Lifestyle10.webp";
import Lifestyle11 from "../assets/Lifestyle/Lifestyle11.webp";

const Lifestyle = () => {
  const imagePaths = [
    Lifestyle1,
    Lifestyle2,
    Lifestyle3,
    Lifestyle4,
    Lifestyle5,
    Lifestyle1,
    Lifestyle8,
    Lifestyle9,
    Lifestyle10,
    Lifestyle11,
    Lifestyle1,
    Lifestyle8,
    Lifestyle9,
    Lifestyle10,
    Lifestyle11,
    Lifestyle1,
  ];

  return (
    <>
      <div className="wrapper px-8 pt-12 lg:pt-36">
        <div className="text-left lg:w-full">
          <h1 className="font-sans text-2xl font-semibold text-custom-blue lg:text-[62px]">
            Lifestyle Driven,
          </h1>
          <h1 className="font-sans text-2xl font-normal text-black lg:pb-5 lg:pt-12 lg:text-[62px]">
            20+ Recreational Amenities
          </h1>
        </div>
        <div className="flex flex-col pt-4 text-left lg:w-full lg:gap-6 lg:py-12 lg:pt-16 lg:text-left">
          <p className="text-sm font-thin leading-7 lg:text-base lg:leading-7">
            Entrance Paving with Tree | Clubhouse | Cricket Pitch | Lawn |
            Jogging Path | Sand Pit Kids Play Area | Reflexology Path | Open
            Meditation Stage | Basketball Practice Court Multipurpose Court |
            Kid’s Park | Play Area | Play Equipment | Climbing Wall | Senior
            Citizen Park | Exercise Deck | Outdoor Gym | Pavilion/Gazebo |
            Meditation Area
          </p>
        </div>
        <div className="mt-8">
          <div className="slider relative">
            <div className="slide-track before relative gap-4">
              {imagePaths.map((path, index) => (
                <div className="slide relative" key={index}>
                  <img
                    src={path}
                    height="200"
                    width="350"
                    alt={`Lifestyle ${index + 1}`}
                  />
                </div>
              ))}
            </div>
            <div className="after absolute"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Lifestyle;
