import React, { useState } from "react";
import Lakefacing1 from "../assets/Lakefacing/Lakefacing-1.webp";
import Lakefacing2 from "../assets/Lakefacing/Lakefacing-2.webp";
import Lakefacing3 from "../assets/Lakefacing/Lakefacing-3.webp";
import Lakefacing4 from "../assets/Lakefacing/Lakefacing-4.webp";
import Lakefacing5 from "../assets/Lakefacing/Lakefacing-5.webp";

const Lakefacing = () => {
  const [selectedTitle, setSelectedTitle] = useState(0);
  const [fade, setFade] = useState(false);

  const titles = [
    {
      title: "Abundance in Wealth & prosperity as per Vastu",
      image: Lakefacing1,
    },
    {
      title: "Improved Well-being and Happiness",
      image: Lakefacing2,
    },
    {
      title: "Reduces Stress and Anxiety",
      image: Lakefacing3,
    },
    {
      title: "Fresher Air and Increased Quality of Sleep",
      image: Lakefacing4,
    },
    {
      title: "Breathtaking Scenic Beauty",
      image: Lakefacing5,
    },
  ];

  const handleTitleClick = (index) => {
    if (index !== selectedTitle) {
      setFade(true);
      setTimeout(() => {
        setSelectedTitle(index);
        setFade(false);
      }, 250);
    }
  };

  return (
    <div className="wrapper hidden px-8 pt-10 lg:block lg:pt-36">
      <div className="text-left lg:w-full">
        <h1 className="font-sans text-2xl font-semibold text-custom-blue lg:text-[62px]">
          Why Lakefacing
        </h1>
        <h1 className="font-sans text-2xl font-normal text-black lg:pb-5 lg:pt-12 lg:text-[62px]">
          though?
        </h1>
      </div>

      <div className="pt-10 lg:flex lg:items-center lg:gap-3 lg:pt-20">
        <div className="mb-8 lg:mb-0 lg:w-1/3">
          <img
            src={titles[selectedTitle].image}
            alt={titles[selectedTitle].title}
            className={`max-h-[37rem] w-full transition-opacity duration-500 ease-in-out ${
              fade ? "opacity-0" : "opacity-100"
            }`}
          />
        </div>

        <div className="lg:w-2/3 lg:pl-8">
          <div className="flex flex-col gap-4 lg:gap-10">
            {titles.map((item, index) => (
              <div
                key={index}
                className={`cursor-pointer border-b text-base transition-all duration-300 lg:text-3xl lg:leading-[4.67rem] ${
                  index === selectedTitle
                    ? "border-custom-blue font-normal"
                    : "border-gray-300 font-normal opacity-30 hover:border-custom-blue hover:opacity-100"
                }`}
                onClick={() => handleTitleClick(index)}
              >
                {item.title}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lakefacing;
