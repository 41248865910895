import React, { useState, useRef, useEffect } from "react";
import Loader from "./Loader";
import ThankYouPopup from "./ThankYouPopup";

function triggerGoogleTag() {
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments); // Use window.dataLayer instead of just dataLayer
  }
  gtag("js", new Date());
  gtag("config", "AW-820023030");
}


function getUrlParams() {
  const params = new URLSearchParams(window.location.search);
  const source = params.get("utm_source");
  const medium = params.get("utm_medium");
  const campaign = params.get("utm_campaign");
  console.log("Extracted URL Params:", { source, medium, campaign });
  return { source, medium, campaign };
}

const SiteVisitForm = ({ title, className = "" }) => {
  const [formData, setFormData] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Message: "",
    Authorization: false,
  });

  const [urlParams, setUrlParams] = useState(getUrlParams());

  useEffect(() => {
    console.log("Form Data on Init:", formData);
  }, []);

  const [phoneError, setPhoneError] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [formError, setFormError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    if (showPopup) {
      window.history.pushState(null, "", "/thankyou");
    } else {
      window.history.pushState(null, "", "/");
    }
  }, [showPopup]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });

    if (name === "Phone") {
      validatePhoneNumber(value);
    }

    if (
      (name === "Name" || name === "Email" || name === "Phone") &&
      formError
    ) {
      setFormError("");
    }
  };

  const validatePhoneNumber = (value) => {
    const sanitizedValue = value.replace(/[^0-9]/g, "");
    const isValidLength = sanitizedValue.length === 10;
    const startsWithValidDigit = /^[6-9]/.test(sanitizedValue);

    if (!isValidLength || !startsWithValidDigit) {
      setPhoneError(
        "Phone number should have exactly 10 digits and start with 6, 7, 8, or 9.",
      );
    } else {
      setPhoneError("");
    }
  };

  const validateForm = () => {
    if (
      !formData.Name ||
      !formData.Email ||
      !formData.Phone ||
      !formData.Authorization
    ) {
      setFormError("Please fill in all required fields and authorize contact.");
      return false;
    }
    if (phoneError) {
      setFormError("Please enter a valid phone number.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setSubmitting(true);

    const formDatab = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      formDatab.append(key, value);
    });

    formDatab.append("DateReceived", new Date());
    formDatab.append("Source", urlParams.source || "organic");
    formDatab.append("Medium", urlParams.medium || "organic");
    formDatab.append("Campaign", urlParams.campaign || "organic");

    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbxQXpSCT-T9anMPHjdXlOwPf_aCSDVeNPlmqyX9vB-d_VssXsmG2LufoHXa1kwjc5Sr/exec",
        {
          method: "POST",
          body: formDatab,
        },
      );
      const data = await response.text();
      console.log(data);
      if (data.toLowerCase().includes("success")) {
        setFormData({
          Name: "",
          Email: "",
          Phone: "",
          Message: "",
          Authorization: false,
        });
        setPhoneError("");
        setShowPopup(true);
        triggerGoogleTag();
      } else {
        console.log("Error submitting the form");
        setFormError(
          "There was an issue submitting the form. Please try again.",
        );
      }
    } catch (error) {
      console.log("Fetch error: ", error);
      setFormError("There was an issue submitting the form. Please try again.");
    } finally {
      setSubmitting(false);
      formRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <div ref={formRef}>
      <form
        className={`flex flex-col gap-6 bg-white py-4 lg:gap-6 lg:px-10 lg:py-8 ${className}`}
        onSubmit={handleSubmit}
      >
        {title && (
          <h2 className="pb-2 text-center text-xl font-semibold text-custom-blue lg:text-3xl">
            {title}
          </h2>
        )}
        <div className="flex flex-col ">
          <label className="text-sm text-custom-blue lg:pb-2" htmlFor="Name">
            Name
          </label>
          <input
            className="mb-4 bg-custom-gray-1 p-2 text-sm"
            type="text"
            id="Name"
            name="Name"
            required
            placeholder="Your name"
            value={formData.Name}
            onChange={handleInputChange}
          />

          <label className="text-sm text-custom-blue lg:pb-2" htmlFor="Email">
            Email
          </label>
          <input
            className="mb-4 bg-custom-gray-1 p-2 text-sm"
            type="email"
            id="Email"
            name="Email"
            required
            placeholder="Your email"
            value={formData.Email}
            onChange={handleInputChange}
          />

          <label className="text-sm text-custom-blue lg:pb-2" htmlFor="Phone">
            Phone Number
          </label>
          <input
            className="mb-4 bg-custom-gray-1 p-2 text-sm"
            type="tel"
            id="Phone"
            name="Phone"
            required
            placeholder="Your phone number"
            value={formData.Phone}
            onChange={handleInputChange}
          />
          {phoneError && <p className="text-sm text-red-500">{phoneError}</p>}

          <label className="text-sm text-custom-blue lg:pb-2" htmlFor="Message">
            Message
          </label>
          <textarea
            className="mb-4 h-24 resize-none bg-custom-gray-1 p-2 text-sm"
            id="Message"
            name="Message"
            placeholder="Messages if any"
            value={formData.Message}
            onChange={handleInputChange}
          />

          <label
            className="text-[0.7rem] text-custom-blue lg:pb-2"
            htmlFor="Authorization"
          >
            <input
              className="mr-2 mt-2"
              type="checkbox"
              id="Authorization"
              name="Authorization"
              checked={formData.Authorization}
              onChange={handleInputChange}
            />
            I authorize company representatives to Call or Whatsapp me about its
            products. *
          </label>
        </div>
        {formError && (
          <p className="mt-3 text-center text-sm text-red-500">{formError}</p>
        )}
        <div className="flex items-center justify-center">
          {submitting ? (
            <Loader />
          ) : (
            <button className="w-full bg-black p-2 text-white" type="submit">
              Submit
            </button>
          )}
        </div>
      </form>
      {showPopup && <ThankYouPopup onClose={() => setShowPopup(false)} />}
    </div>
  );
};

export default SiteVisitForm;
