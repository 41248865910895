import React from "react";
import desktopImage from "../assets/banner/web-banner.webp";
import mobileImage from "../assets/banner/phone-banner.webp";
import "./HeroSection.css";

function HeroSection() {
  return (
    <div id="banner" className="w-full">
      <img
        src={desktopImage}
        alt="Banner"
        className="desktop-image"
        loading="lazy"
      />
      <img
        src={mobileImage}
        alt="Banner"
        className="mobile-image"
        loading="lazy"
      />
    </div>
  );
}

export default HeroSection;
