import React, { useState, useRef } from "react";
import Lakefacing1 from "../assets/Lakefacing/Lakefacing-1.webp";
import Lakefacing2 from "../assets/Lakefacing/Lakefacing-2.webp";
import Lakefacing3 from "../assets/Lakefacing/Lakefacing-3.webp";
import Lakefacing4 from "../assets/Lakefacing/Lakefacing-4.webp";
import Lakefacing5 from "../assets/Lakefacing/Lakefacing-5.webp";

const LakefacingAccordion = () => {
  const [selectedTitleIndex, setSelectedTitleIndex] = useState(null);
  const contentRefs = useRef([]);

  const titles = [
    {
      title: "Abundance in Wealth & prosperity as per Vastu",
      image: Lakefacing1,
    },
    {
      title: "Improved Well-being and Happiness",
      image: Lakefacing2,
    },
    {
      title: "Reduces Stress and Anxiety",
      image: Lakefacing3,
    },
    {
      title: "Fresher Air and Increased Quality of Sleep",
      image: Lakefacing4,
    },
    {
      title: "Breathtaking Scenic Beauty",
      image: Lakefacing5,
    },
  ];

  const handleTitleClick = (index) => {
    setSelectedTitleIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className=" px-8 pt-[3.5rem] lg:hidden block lg:px-60 lg:pt-[10rem]">
      <div className="text-left lg:w-full">
        <h1 className="font-sans text-2xl font-semibold text-custom-blue lg:text-[62px]">
          Why Lakefacing
        </h1>
        <h1 className="font-sans text-2xl font-normal text-black lg:pb-5 lg:pt-16 lg:text-[62px]">
          though?
        </h1>
      </div>

      <div className="pt-6 lg:flex lg:items-center lg:gap-3 lg:pt-20">
        <div className="lg:w-full">
          {titles.map((item, index) => (
            <div key={index} className="mb-4">
              <div
                className={`flex cursor-pointer items-center justify-between text-sm lg:text-3xl ${
                  index === selectedTitleIndex
                    ? "font-normal text-custom-blue"
                    : "font-normal text-gray-600 opacity-30"
                }`}
                onClick={() => handleTitleClick(index)}
              >
                {item.title}
                <span
                  className={`transform text-[0.5rem] transition-transform duration-300 ${
                    index === selectedTitleIndex ? "rotate-180" : "rotate-0"
                  }`}
                >
                  ▼
                </span>
              </div>
              <div className="my-2 border-b border-gray-300"></div>
              <div
                className={`transition-height overflow-hidden duration-300 ease-in-out`}
                style={{
                  height:
                    index === selectedTitleIndex
                      ? `${contentRefs.current[index]?.scrollHeight}px`
                      : "0px",
                }}
              >
                <div ref={(el) => (contentRefs.current[index] = el)}>
                  <img
                    src={item.image}
                    alt={item.title}
                    className={`max-h-[37rem] w-full transition-opacity duration-500 ease-in-out ${
                      index === selectedTitleIndex ? "opacity-100" : "opacity-0"
                    }`}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LakefacingAccordion;
