import { useState } from "react";
import divineLogo from "../assets/grand-icon-footer.svg";

const Footer = () => {
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);

  const togglePrivacyPolicyModal = () => {
    setShowPrivacyPolicyModal((prev) => !prev);
    toggleBodyScroll();
  };

  const toggleDisclaimerModal = () => {
    setShowDisclaimerModal((prev) => !prev);
    toggleBodyScroll();
  };

  const toggleBodyScroll = () => {
    // Toggle the 'overflow-hidden' class based on modal state
    if (showPrivacyPolicyModal || showDisclaimerModal) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  return (
    <footer>
      {showPrivacyPolicyModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="max-h-full max-w-7xl overflow-y-auto rounded-lg bg-white p-8">
            <div className="flex justify-end">
              <button
                onClick={togglePrivacyPolicyModal}
                className="text-gray-400 hover:text-gray-500 focus:text-gray-500 focus:outline-none"
                aria-label="Close"
              >
                <svg
                  className="h-6 w-6 text-black"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <h2 className="pb-4 text-xl font-bold">Privacy Policy:</h2>
            <p className="pb-4">
            This policy was last modified on 14th April 2023. The information
            found on this website is conceptual and not a legal offering. The
            promoter reserves the right to change, alter, add or delete any of
            the specifications mentioned herein without prior permission or
            notice. We collect information from you when you register on our
            site or fill out a form. Any data we request that is not required
            will be specified as voluntary or optional. When filling out a
            form on our site, for any of the above-mentioned reasons, you may
            be asked to enter your: name, e-mail address or phone number. You
            may, however, visit our site anonymously. Like most websites, we
            use cookies to enhance your experience, gather general visitor
            information, and track visits to our website. Please refer to the
            'do we use cookies?' section below for information about cookies
            and how we use them. Any of the information we collect from you
            may be used in one of the following ways:
            <li>
              <ul>
                To personalize your experience (your information helps us to
                better respond to your individual needs){" "}
              </ul>
              <ul>
                To improve our website (we continually strive to improve our
                website offerings based on the information and feedback we
                receive from you){" "}
              </ul>
              <ul>
                To improve customer service (your information helps us to more
                effectively respond to your customer service requests and
                support needs){" "}
              </ul>
              <ul>
                To process transactions (Your information, whether public or
                private, will not be sold, exchanged, transferred, or given to
                any other company for any reason whatsoever, without your
                consent, other than for the express purpose of delivering the
                purchased product or service requested by the customer){" "}
              </ul>
              <ul>To send periodic emails. </ul>
              <ul>
                The email address you provide for order processing, may be
                used to send you information and updates pertaining to your
                order or request, in addition to receiving occasional company
                news, updates, promotions, related product or service
                information, etc.
              </ul>
            </li>
            <br></br> Note: If at any time you would like to unsubscribe from
            receiving future emails from us, simply respond to the email
            received letting us know the same & we will have you taken off
            from our database. We implement a variety of security measures to
            maintain the safety of your personal information when you submit a
            request or enter, submit, or access your personal information.
            These security measures include: password protected directories
            and databases to safeguard your information. Yes (Cookies are
            small files that a site or its service provider transfers to your
            computer's hard drive through your Web browser [if you allow] that
            enables the sites or service provider’s systems to recognize your
            browser and capture and remember certain information). We do not
            sell, trade, or otherwise transfer to outside parties your
            personally identifiable information. This does not include trusted
            third parties who assist us in operating our website, conducting
            our business, or servicing you, so long as those parties agree to
            keep this information confidential. We may also release your
            information when we believe release is appropriate to comply with
            the law, enforce our site policies, or protect ours or others'
            rights, property, or safety. However, non- personally identifiable
            visitor information may be provided to other parties for
            marketing, advertising, or other uses. Occasionally, at our
            discretion, we may include or offer third party products or
            services on our website. These third party sites have separate and
            independent privacy policies. We therefore have no responsibility
            or liability for the content and activities of these linked sites.
            Nonetheless, we seek to protect the integrity of our site and
            welcome any feedback about these sites. This online Privacy Policy
            applies only to information collected through our website and not
            to information collected offline. By using our site, you consent
            to our Privacy Policy. If we decide to change our Privacy Policy,
            we will post those changes on this page, and/or update the Privacy
            Policy modification date at the top of this page. Policy changes
            will apply only to information collected after the date of the
            change. We pledge to you, our customer, that we have made a
            dedicated effort to bring our Privacy Policy in line with the
            following important privacy laws and initiatives:
          </p>
          </div>
        </div>
      )}

      {showDisclaimerModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="max-h-full max-w-4xl overflow-y-auto rounded-lg bg-white p-8">
            <div className="flex justify-end">
              <button
                onClick={toggleDisclaimerModal}
                className="text-gray-400 hover:text-gray-500 focus:text-gray-500 focus:outline-none"
                aria-label="Close"
              >
                <svg
                  className="h-6 w-6 text-black"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <h2 className="mb-4 text-xl font-bold">Disclaimer:</h2>
            <p className="mb-4">
            The content presented on this website, including 360-degree views,
            elevations, walkthroughs, E-Brochures, and similar materials, is
            intended solely for illustrative purposes. These elements do not
            constitute any binding agreement or legal commitment on the part
            of Iconhomz. Any expressed viewpoints are not reflective of the
            final deliverables but are artistic depictions. Please note that
            soft furnishings, furniture, and gadgets are not included in the
            offerings. The product and technology showcased, if any, are for
            demonstrative purposes only, and their usage is not guaranteed by
            the company. Specifications provided are indicative and subject to
            potential changes decided upon by the company or relevant
            authorities. Minor variations might occur during the construction
            process. The scope, quantity, and variety of equipment/appliances
            and their respective brands are tentative and may be subject to
            alteration at the sole discretion of the company. Any objections
            in this regard cannot be raised by applicants, allottees, or any
            other individuals. It is your responsibility to assess the
            accuracy, comprehensiveness, and utility of any opinions, advice,
            services, or other information provided. All information on this
            platform is shared with the understanding that authors,
            publishers, and distributors are not providing legal or
            professional advice on specific matters. They bear no liability
            for its use. For personalized situations, consult your own legal
            or tax advisor. Under no circumstances shall Iconhomz, its
            related, affiliated, and subsidiary entities be held liable for
            direct, indirect, special, incidental, or consequential damages
            arising from the utilization of the information provided herein.
          </p>
          </div>
        </div>
      )}

      <div className="flex items-center justify-between gap-4 bg-custom-blue p-4 px-8 text-white lg:px-36">
        <div className="hidden md:block lg:pl-24">
          <img className="w-24 lg:w-32" src={divineLogo} alt="Divine Logo" />
        </div>
        <div className="flex gap-6 lg:flex-row lg:gap-[2rem]">
          <button
            className="text-[0.7rem] lg:text-sm"
            onClick={togglePrivacyPolicyModal}
          >
            Privacy Policy
          </button>
          <button
            className="text-xs lg:text-sm"
            onClick={toggleDisclaimerModal}
          >
            Disclaimer
          </button>
        </div>
        <div className="text-[10px] lg:pr-24 lg:text-sm">
          <p className="hidden lg:block">
            <b className="font-medium">RERA No. - </b>
            PRM/KA/RERA/1251/308/PR/300523/005960
          </p>
          <p className="lg:hidden">
            <b className="font-medium">RERA No. - </b>PRM/KA/RERA/<br />
            1251/308/PR/300523/005960
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
