import React, { useState } from "react";

const DistancesAccordion = () => {
  // Set the initial state to 0 to always show the first index open
  const [selectedTitleIndex, setSelectedTitleIndex] = useState(0);

  const data = [
    {
      title: "IT Parks",
      subtitles: [
        "HCL Technologies",
        "Biocon Park",
        "Wipro Technologies",
        "Tata Consultancy Services",
        "Infosys Ltd",
      ],
    },
    {
      title: "Retail & Entertainment",
      subtitles: [
        "Dmart",
        "Decathlon",
        "M5 E-City Mall ",
        "Vega City Mall ",
        "Nexus Whitefield",
      ],
    },
    {
      title: "Healthcare",
      subtitles: [
        "Narayana Health City",
        "Mazumdar Shaw Medical Center",
        "Sunray Multispecialty Hospital",
        "Vimalaya Hospital",
        "Fortis Hospital",
      ],
    },
    {
      title: "Educational Institutions",
      subtitles: [
        "National Public School",
        "Mega International School",
        "Tremis School",
        "PES University",
        "Delhi Public School",
      ],
    },
  ];

  const handleTitleClick = (index) => {
    if (index === selectedTitleIndex) {
      setSelectedTitleIndex(null);
    } else {
      setSelectedTitleIndex(index);
    }
  };

  return (
    <div className="block px-10 pt-6 lg:hidden lg:px-60">
      <div className="text-center lg:text-left">
        {data.map((item, index) => (
          <div key={index} className="mb-4">
            <div
              className={`flex cursor-pointer items-center justify-between text-xl lg:text-3xl ${
                index === selectedTitleIndex
                  ? "font-medium text-custom-blue"
                  : "font-medium text-gray-600 opacity-30"
              }`}
              onClick={() => handleTitleClick(index)}
            >
              {item.title}
              <span
                className={`transform text-[0.5rem] transition-transform duration-300 ${
                  index === selectedTitleIndex ? "rotate-180" : "rotate-0"
                }`}
              >
                ▼
              </span>
            </div>
            <div className="my-2 border-b border-gray-300"></div>
            <div
              className={`transition-max-height overflow-hidden duration-300 ${
                index === selectedTitleIndex ? "max-h-96" : "max-h-0"
              }`}
            >
              <ul className="mt-2 flex list-disc flex-col gap-3 pl-5 text-base font-normal text-gray-800">
                {item.subtitles.map((subtitle, subIndex) => (
                  <li className="text-left text-sm" key={subIndex}>
                    {subtitle}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DistancesAccordion;
