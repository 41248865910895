import React, { useState } from "react";

const Distances = () => {
  const [selectedTitleIndex, setSelectedTitleIndex] = useState(0);
  const [fade, setFade] = useState(false);

  const data = [
    {
      title: "IT Parks",
      subtitles: [
        "HCL Technologies",
        "Biocon Park",
        "Wipro Technologies",
        "Tata Consultancy Services",
        "Infosys Ltd",
      ],
    },
    {
      title: "Retail & Entertainment",
      subtitles: [
        "Dmart",
        "Decathlon",
        "M5 E-City Mall ",
        "Vega City Mall ",
        "Nexus Whitefield",
      ],
    },
    {
      title: "Healthcare",
      subtitles: [
        "Narayana Health City",
        "Mazumdar Shaw Medical Center",
        "Sunray Multispecialty Hospital",
        "Vimalaya Hospital",
        "Fortis Hospital",
      ],
    },
    {
      title: "Educational Institutions",
      subtitles: [
        "National Public School",
        "Mega International School",
        "Tremis School",
        "PES University",
        "Delhi Public School",
      ],
    },
  ];

  const handleTitleClick = (index) => {
    if (index !== selectedTitleIndex) {
      setFade(true);
      setTimeout(() => {
        setSelectedTitleIndex(index);
        setFade(false);
      }, 250);
    }
  };

  return (
    <div className="wrapper hidden px-8 pt-10 lg:block">
      <div className="text-center lg:text-left">
        <div className="flex justify-center gap-10 space-x-4 lg:justify-center">
          {data.map((item, index) => (
            <div
              key={index}
              className={`cursor-pointer text-3xl ${
                index === selectedTitleIndex
                  ? "font-medium text-custom-blue"
                  : "font-medium text-gray-600 opacity-30"
              }`}
              onClick={() => handleTitleClick(index)}
            >
              {item.title}
            </div>
          ))}
        </div>
        <div className="my-4 border-t border-gray-300"></div>
        <div
          className="flex justify-center gap-10 transition-opacity duration-200 ease-in-out"
          style={{ opacity: fade ? 0 : 1 }}
        >
          {data[selectedTitleIndex].subtitles.map((subtitle, index) => (
            <React.Fragment key={index}>
              <div className="text-base font-normal text-gray-800">
                {subtitle}
              </div>
              {index < data[selectedTitleIndex].subtitles.length - 1 && (
                <span className="text-xl font-extralight text-gray-800 opacity-10">
                  |
                </span>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Distances;
