import React from "react";
import locationImage from "../assets/location-centered.webp";

const Location = () => {
  return (
    <div className="wrapper px-8 pt-2 lg:pt-36">
      <div className="text-left lg:w-full">
        <h1 className="font-sans text-2xl font-semibold text-custom-blue lg:text-[62px]">
          Location Centered,
        </h1>
        <h1 className="font-sans text-2xl font-normal text-black lg:pb-5 lg:pt-12 lg:text-[62px]">
          Electronic City Offers It all.
        </h1>
      </div>
      <img
        src={locationImage}
        alt="Plot in Electronic City"
        className="h-auto w-full object-cover pt-4"
      />
    </div>
  );
};

export default Location;
