import React from "react";
import plotMobile from "../assets/plot.webp";
import plotDesktop from "../assets/graph-image.webp";

const Plot = () => {
  return (
    <>
      <div className="wrapper px-8 pt-6 lg:pt-12">
        <div className="text-left lg:w-full">
          <h1 className="font-sans text-2xl font-semibold text-custom-blue lg:text-[62px]">
            Plot in Electronic City?
          </h1>
          <h1 className="font-sans text-2xl font-normal text-black lg:pb-5 lg:pt-12 lg:text-[62px]">
            Why Not!
          </h1>
        </div>
        <p className="pt-4 text-sm font-thin leading-7 lg:pr-[37rem] lg:pt-10 lg:text-base lg:leading-7">
          Graphical Representation of Land Price Index.
        </p>
      </div>
      <div className="mt-8 lg:px-60">
        <img
          src={plotMobile}
          alt="Plot in Electronic City - Mobile"
          className="h-auto w-full object-cover lg:hidden"
        />
        <img
          src={plotDesktop}
          alt="Plot in Electronic City - Desktop"
          className="hidden h-auto w-full object-cover lg:block"
        />
      </div>
    </>
  );
};

export default Plot;
