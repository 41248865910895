import React from "react";
import bmrdaLogo from "../assets/bmrda.webp";

const Usp = () => {
  const uspItems = [
    { label: "05", subLabel: "Acres" },
    { label: "80", subLabel: "Units" },
    { label: "20+", subLabel: "Amenities" },
    { label: "BMRDA", subLabel: "Approved" },
  ];

  return (
    <div className="wrapper px-4 pt-8 ">
      <div className="grid grid-cols-2 gap-8 lg:grid-cols-4">
        {uspItems.map((item, index) => (
          <div
            key={index}
            className="flex flex-col items-center justify-center gap-2 lg:gap-12"
            style={{ height: "100%", maxWidth: "300px" }}
          >
            <div
              className="text-center text-4xl font-bold text-custom-blue lg:text-[62px]"
              style={{ maxHeight: "100px", maxWidth: "250px" }}
            >
              {item.isLogo ? (
                <img
                  src={item.label}
                  alt="BMRDA Logo"
                  className="mx-auto max-h-[70px] max-w-[70px] lg:max-h-[150px] lg:max-w-[200px]"
                />
              ) : (
                item.label
              )}
            </div>
            <div
              className="text-center text-xs font-thin text-custom-gray lg:text-[32px]"
              style={{ maxHeight: "32px" }}
            >
              {item.subLabel}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Usp;
