import hdfc from "../assets/bank/hdfc.svg";
import icici from "../assets/bank/icici.svg";
import sbi from "../assets/bank/sbi.svg";
import canarabank from "../assets/bank/canarabank.svg";

const Banks = () => {
  const uspItems = [
    { label: "05", subLabel: "Access Land" },
    { label: "80", subLabel: "Units" },
    { label: "20 +", subLabel: "Amenities" },
    { label: "BMRDA", subLabel: "e-Khata" },
  ];

  return (
    <div className="wrapper px-8 pt-[4.5rem] lg:pt-36">
      <div className="flex gap-2 text-left lg:w-full lg:gap-5">
        <h1 className="font-sans text-2xl font-semibold text-custom-blue lg:text-[62px]">
          Bank
        </h1>
        <h1 className="font-sans text-2xl font-normal text-black lg:pb-5 lg:text-[62px]">
          Approvals
        </h1>
      </div>
      <div className="flex gap-8 pb-12 pt-6 lg:flex lg:items-start lg:justify-start lg:gap-24 lg:py-20">
        <div className="overflow-hidden rounded">
          <img src={hdfc} alt="hdfc-bank" className="w-10 lg:w-[5rem]" />
        </div>

        <div className="overflow-hidden rounded">
          <img src={icici} alt="icici-bank" className="w-20 lg:w-[13rem]" />
        </div>

        <div className="overflow-hidden rounded">
          <img src={sbi} alt="sbi-bank" className="w-20 lg:w-[13rem]" />
        </div>

        <img
          src={canarabank}
          alt="canara-bank"
          className="w-20 lg:h-[7rem] lg:w-[13rem]"
        />
      </div>
    </div>
  );
};

export default Banks;
