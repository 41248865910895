import { FaWhatsapp } from "react-icons/fa";
import Navbar from "./components/Navbar";
import Analytics from "./components/UrbanNature";
import { useState } from "react";
import HeroSection from "./components/HeroSection";
import './index.css';
import Usp from "./components/Usp";
import Plot from "./components/Plot";
import Location from "./components/Location";
import Lifestyle from "./components/Lifestyle";
import Lakefacing from "./components/Lakefacing";
import SiteVisit from "./components/SiteVisit";
import GetInTouch from "./components/GetInTouch";
import Distances from "./components/Distances";
import Footer from "./components/Footer";
import Banks from "./components/Banks";
import DistancesAccordion from "./components/DistancesAccordion";
import LakefacingAccordion from "./components/LakefacingAccordion";

function App() {
  const [whatsappNumber] = useState("8892564020");

  const handleWhatsappClick = () => {
    window.open(`https://wa.me/${whatsappNumber}`, "_blank");
  };
  return (
    <div>
      <Navbar />
      <HeroSection />
      <Analytics />
      <Usp />
      <Banks />
      <Plot />
      <Location />
      <Distances />
      <DistancesAccordion />
      <Lifestyle />
      <Lakefacing />
      <LakefacingAccordion/>
      <SiteVisit />
      <GetInTouch />
      <Footer />
      <div
        onClick={handleWhatsappClick}
        className="fixed bottom-20 right-20 z-50 cursor-pointer rounded-full bg-green-600 p-2 text-white"
        style={{ position: "fixed", bottom: 20, right: 20 }}
      >
        <FaWhatsapp size={32} color="white" />
      </div>
    </div>
  );
}

export default App;
