import React from "react";
import SiteVisitForm from "./Components/SiteVisitForm";

const SiteVisit = () => {
  return (
    <div className="wrapper px-8 pt-12 lg:pb-12 lg:pt-36">
      <div className="flex gap-2 text-left lg:w-full lg:gap-5">
        <h1 className="font-sans text-2xl font-semibold text-custom-blue lg:text-[62px]">
          Schedule
        </h1>
        <h1 className="font-sans text-2xl font-normal text-black lg:pb-5 lg:text-[62px]">
          Site Visit
        </h1>
      </div>
      <div className="flex flex-col pt-4 lg:flex-row lg:gap-24 lg:pt-16">
        <div className="hidden w-full md:block lg:w-1/2">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15562.672567091453!2d77.6761814!3d12.80004!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae6d8c43339c75%3A0xbd8a8218cd5ba551!2sGrand%20Icon%203!5e0!3m2!1sen!2sin!4v1718886819334!5m2!1sen!2sin"
            className="h-60 w-full lg:h-[500px] lg:w-[700px]"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="pb-2 lg:w-1/2 ">
          <SiteVisitForm className="!pr-0 !pt-2" />
        </div>
      </div>
    </div>
  );
};

export default SiteVisit;
