import React from "react";

const UrbanNature = () => {
  return (
    <div className="wrapper px-8 lg:pt-16">
      <div className="text-left lg:w-full">
        <h1 className="font-sans text-2xl font-semibold text-custom-blue lg:text-[62px]">
          Urban & Nature
        </h1>
        <h1 className="font-sans text-2xl font-normal text-black lg:pb-5 lg:pt-10 lg:text-[62px]">
          Connected.
        </h1>
      </div>
      <div className="flex flex-col gap-4 pt-4 text-center lg:w-full lg:gap-6 lg:py-12 lg:text-left">
        <p className="text-left text-sm font-thin leading-7 lg:text-base lg:leading-6">
          Immerse yourself in the serenity of Kachanayakanahalli Lake, the
          gentle sounds, and the calming views of the water body.
        </p>
        <p className="text-left text-sm font-thin leading-7 lg:text-base lg:leading-6">
          Grand Icon 3 is a thoughtfully designed community residing in the
          prime location of Bommasandra, near Electronic City, offering the best
          of both Urban and Lakeside living. The project consists of a limited
          collection of 80 plots, each nestled within a tranquil 5-acre
          landscape, with six themed parks providing ample space for recreation
          and relaxation. The proximity to Electronic City ensures easy access
          to workplaces and all the amenities a thriving city offers.
        </p>
      </div>
    </div>
  );
};

export default UrbanNature;
