import React, { useEffect } from "react";

function ThankYouPopup({ onClose }) {
  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=AW-820023030";
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "AW-820023030");

      // Track the form submission event
      gtag("event", "conversion", {
        send_to: "AW-820023030/VvG3COmnpL4ZEPadgocD", // Replace with your actual conversion ID
      });
    };

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div className="fixed left-0 top-0 flex h-full w-full items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="flex w-full max-w-sm flex-col items-center justify-center rounded-lg bg-white p-8">
        <p className="mb-4 text-center text-xl font-semibold">Thank You!</p>
        <p className="mb-4 text-center">
          Your form has been submitted successfully.
        </p>
        <button
          onClick={onClose}
          className="w-1/2 rounded-md bg-custom-blue px-8 py-2 text-center text-white"
        >
          Close
        </button>
      </div>
    </div>
  );
}

export default ThankYouPopup;
